import { Link, useLocation } from "react-router-dom";
import s from "./ConfirmPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function ConfirmPage() {
  const location = useLocation();
  const { paymentMethod, email, productName, price, paymentUrl } =
    location.state;

  const handlePayment = () => {
    window.Telegram.WebApp.openLink(paymentUrl);
    window.Telegram.WebApp.close();
  };

  return (
    <div className="wrapper">
      <div className="container">
        <div className="content">
          <div className="progress">
            <div className="circle completed">
              <FontAwesomeIcon icon={faCheck} style={{ color: "#2c2c2e" }} />
              <span>Выбор</span>
            </div>
            <div className="line completed"></div>
            <div className="circle completed">
              <FontAwesomeIcon icon={faCheck} style={{ color: "#2c2c2e" }} />
              <span>Данные</span>
            </div>
            <div className="line"></div>
            <div className="circle">
              <span>Оплата</span>
            </div>
          </div>
          <div className={s.header}>Убедитесь, что введённые данные верны</div>
          <div className={s.confirmation}>
            <div className={s.item}>
              <span className={s.label}>Наименование товара</span>
              <span className={s.value} id="product-name">
                {productName}
              </span>
            </div>
            <div className={s.item}>
              <span className={s.label}>Метод оплаты</span>
              <span className={s.value} id="payment-method">
                {paymentMethod}
              </span>
            </div>
            <div className={s.item}>
              <span className={s.label}>Ваш E-mail</span>
              <span className={s.value} id="email">
                {email}
              </span>
            </div>
            <div className={s.item}>
              <span className={s.label}>Стоимость</span>
              <span className={s.value} id="price">
                {price} RUB
              </span>
            </div>
          </div>
        </div>
        <div className="empty_space"></div>
        <div className="buttons">
          <button onClick={handlePayment} className={s.button}>
            Перейти к оплате
          </button>
          <Link
            to="/payment_form_page"
            state={location.state}
            className={`${s.button} ${s.last}`}
          >
            Вернуться назад
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPage;
